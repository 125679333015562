
import { defineComponent, ref, reactive } from 'vue'
import { useStore } from '@/store'
import CustomSlider from '@/components/UI/CustomSlider.vue'
import Wizard2 from '@/components/Step1/wizard2.vue'
import Wizard3 from '@/components/Step1/wizard3.vue'
import Wizard4 from '@/components/Step1/wizard4.vue'
import Wizard5 from '@/components/Step1/wizard5.vue'
import Wizard7 from '@/components/Step1/wizard7.vue'
import Wizard8 from '@/components/Step1/wizard8.vue'
import Wizard9 from '@/components/Step1/wizard9.vue'
import Wizard10 from '@/components/Step1/wizard10.vue'
import CargoSpace from '@/components/CargoSpace.vue'
import Button from '@/components/UI/Button.vue'
import wizardFuelType from '@/mixins/wizards/WizardFuelType'
// import wizardEquipments from '@/mixins/wizards/WizardEquipments'
export default defineComponent({
  emits: ['onClose', 'applyFilters', 'discardFilters'],
  components: {
    CustomSlider,
    Wizard2,
    Wizard3,
    Wizard4,
    Wizard5,
    Wizard7,
    Wizard8,
    Wizard9,
    Wizard10,
    Button,
    CargoSpace
  },
  setup() {
    const store = useStore()
    const el = ref<HTMLDivElement>(document.createElement('div'))
    const { checkboxesStep2 } = wizardFuelType()
    const changeFilterSlider = ($event) => {
      console.log('slider', $event)
    }
    // wizard step 2
    const getSelectedValue = (storedStep, checkboxArray) => {
      const storedStepValue = JSON.parse(sessionStorage.getItem(`${storedStep}`) || '[]')
      storedStepValue.forEach((itemName) => {
        checkboxArray.forEach((item) => {
          if (item.title === itemName) {
            item.isActive = true
          }
        })
      })
      return checkboxArray
    }
    const fuelTypeCheckboxes = sessionStorage.getItem('fuel_type')
      ? getSelectedValue('fuel_type', checkboxesStep2)
      : checkboxesStep2
    // wizard step 3
    const checkboxesStep3 = reactive([
      {
        id: 'styled-checkbox1',
        value: 'SUV',
        isActive: false
      },
      {
        id: 'styled-checkbox2',
        value: 'Hatchbacks',
        isActive: false
      },
      {
        id: 'styled-checkbox3',
        value: 'Saloons',
        isActive: false
      },
      {
        id: 'styled-checkbox4',
        value: 'Coupes',
        isActive: false
      },
      {
        id: 'styled-checkbox5',
        value: 'Estate cars',
        isActive: false
      },
      {
        id: 'styled-checkbox6',
        value: 'MPV',
        isActive: false
      },
      {
        id: 'styled-checkbox8',
        value: 'Convertibles',
        isActive: false,
        isDisabled: false
      }
    ])
    if (sessionStorage.getItem('car_policy_body_type')) {
      const storedStepValue = JSON.parse(sessionStorage.getItem('car_policy_body_type') || '[]')
      checkboxesStep3.forEach((item) => {
        item.isDisabled = !storedStepValue.includes(item.value)
      })
    }
    const getSelectedBodyType = (storedStep, checkboxArray) => {
      const storedStepValue = JSON.parse(sessionStorage.getItem(`${storedStep}`) || '[]')
      storedStepValue.forEach((itemName) => {
        checkboxArray.forEach((item) => {
          if (item.value === itemName) {
            item.isActive = true
          }
        })
      })
      return checkboxArray
    }
    const bodyTypeCheckboxes = sessionStorage.getItem('body_type')
      ? getSelectedBodyType('body_type', checkboxesStep3)
      : checkboxesStep3
    // wizard step 4
    const checkboxesStep4 = reactive([
      {
        id: 'step4-styled-checkbox1',
        value: '_wizard.step4.manual',
        isActive: false,
        title: 'Manual'
      },
      {
        id: 'step4-styled-checkbox2',
        value: '_wizard.step4.automatic',
        isActive: false,
        title: 'Automatic'
      }
    ])
    const gearBoxCheckboxes = sessionStorage.getItem('transmission')
      ? getSelectedValue('transmission', checkboxesStep4)
      : checkboxesStep4
    // wizard step 10
    // const { equipmentCheckboxes } = wizardEquipments()
    return {
      store,
      el,
      fuelTypeCheckboxes,
      bodyTypeCheckboxes,
      gearBoxCheckboxes,
      // equipmentCheckboxes,
      changeFilterSlider
    }
  }
})
