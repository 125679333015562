
import { defineComponent, ref, reactive, watch, onMounted } from 'vue'
import VueSlider from 'vue-slider-component'
import sliderOptions from '@/mixins/sliderOptions'
export default defineComponent({
  components: {
    VueSlider
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const minValue = sessionStorage.getItem('car_policy_doors')
      ? ref(parseInt(JSON.parse(sessionStorage.getItem('car_policy_doors') || '')['min']))
      : ref(2)
    const maxValue = ref(5)
    const getArrayList = () => {
      const list: Array<number> = []
      for (let i = minValue.value; i <= maxValue.value; i++) {
        list.push(i)
      }
      return list.reverse()
    }
    const sliderData = sessionStorage.getItem('car_policy_doors') ? getArrayList() : reactive([5, 4, 3, 2])
    const sliderValue = sessionStorage.getItem('doors')
      ? ref(JSON.parse(sessionStorage.getItem('doors') || ''))
      : minValue
    onMounted(() => {
      if (!props.isFilter) sessionStorage.setItem('doors', JSON.stringify(sliderValue.value))
    })
    const { sliderDataOptions } = sliderOptions()
    const sliderImages = {
      2: 'two-doors',
      3: 'three-doors',
      4: 'four-doors',
      5: 'five-doors'
    }
    watch(
      () => sliderValue.value,
      (currentValue) => {
        sessionStorage.setItem('doors', JSON.stringify(currentValue))
      }
    )
    return { sliderValue, sliderDataOptions, sliderImages, sliderData, minValue, maxValue }
  }
})
