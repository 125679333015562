
import { defineComponent, reactive } from 'vue'
import CarBodyType from '@/components/CarBodyType.vue'
export default defineComponent({
  components: {
    CarBodyType
  },
  props: {
    checkboxesValues: {
      type: Object,
      default: () => ({})
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const carBodyTypeArray: Array<string> = sessionStorage.getItem('body_type')
      ? reactive(JSON.parse(sessionStorage.getItem('body_type') || '[]'))
      : reactive([])
    const onCarBodyCheck = ($event) => {
      const item = $event.target.value
      if (carBodyTypeArray.indexOf(item) === -1) {
        carBodyTypeArray.push(item)
      }
      if (!$event.target.checked) {
        carBodyTypeArray.splice(carBodyTypeArray.indexOf(item), 1)
      }
      if (!carBodyTypeArray.length) sessionStorage.removeItem('body_type')
      else sessionStorage.setItem('body_type', JSON.stringify(carBodyTypeArray))
    }
    return { onCarBodyCheck }
  }
})
