
import { defineComponent, ref, computed, onMounted, reactive } from 'vue'
import checkUserSettings from '@/mixins/checkUserSettings'
import { useStore } from '@/store'
import formatCurrency from '@/filters/CurrencyFormat'
import BackButton from '@/components/UI/BackButton.vue'
import Button from '@/components/UI/Button.vue'
import CarResults from '@/components/CarResults.vue'
import FilterSidebar from '@/components/UI/FilterSidebar.vue'
import { ActionTypes } from '@/store/wizardSteps/actions'
import loader from '@/components/UI/Loader.vue'
import onWindowResize from '@/mixins/onWindowResize'
import { ActionTypes as userCarsActionTypes } from '@/store/userCars/actions'
import { useRouter } from 'vue-router'
import { wizardStepsResponseAllHits } from '@/types'
import Popup from '@/components/Popup.vue'
export default defineComponent({
  components: {
    BackButton,
    Button,
    FilterSidebar,
    CarResults,
    loader,
    Popup
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const wizardResults = computed(() => store.state.wizardSteps.wizardResults)
    const { displayUserSettingPopup, goToAccount } = checkUserSettings()
    const backButtonPressed = computed(() => store.state.wizardSteps.backButtonPressed)
    const loader = computed(() => store.state.wizardSteps.loader)
    const userCarsLoader = computed(() => store.state.userCars.loader)
    const { windowWidth } = onWindowResize()
    const showComplyPopup = ref(false)
    const showMoreVersion = ref(false)
    const options = computed(() => store.state.options.options)
    const complyCarResponse = computed(() => store.state.options.complyCarResponse)
    const wizardInnerHits: { value: wizardStepsResponseAllHits[] } = reactive({ value: [] })
    const showResults = computed(() => (showMoreVersion.value ? wizardInnerHits.value : wizardResults.value))
    const allSavedCars = computed(() => store.state.userCars.savedCars)
    const brandAndModel = ref({})
    const showFilterSidebar = ref(false)
    const position = ref(1)
    const selectionAmount = sessionStorage.getItem('selectionAmount')
      ? ref(JSON.parse(sessionStorage.getItem('selectionAmount')))
      : ref(4)
    const wizardRequestBody = reactive({
      paginate: { offset: 0, limit: 100 }
    })
    const closeComplyPopup = () => {
      showComplyPopup.value = false
    }
    const toggleComplyPopup = () => {
      showComplyPopup.value = !showComplyPopup.value
    }
    const wizardSteps = reactive([
      'budget',
      'fuel_type',
      'plug_in_hybrid',
      'hybrid',
      'electric_drive_range',
      'plug_in_hybrid_drive_range',
      'body_type',
      'transmission',
      'engine_power',
      'cargo_space',
      'towing_capacity',
      'seats',
      'doors',
      'brands'
    ])

    const settingCarPloiciesValues = () => {
      const carPolicyFuelTypes = JSON.parse(sessionStorage.getItem('car_policy_fuel_type') || '[]')
      const filteredFuelTypes = carPolicyFuelTypes.filter((e) => e !== 'plug_in_hybrid' && e !== 'hybrid')

      if (filteredFuelTypes.length > 0) {
        // Setting fuel values
        sessionStorage.setItem('fuel_type', JSON.stringify(filteredFuelTypes))

        if (sessionStorage.getItem('car_policy_electric_range') && filteredFuelTypes.includes('electric')) {
          const carPolicyElectricRange = JSON.parse(sessionStorage.getItem('car_policy_electric_range') || '[]')
          sessionStorage.setItem('electric_drive_range', JSON.stringify(carPolicyElectricRange))
        }
      }

      // Adding Hybrid true if it exists
      if (carPolicyFuelTypes.includes('hybrid')) {
        sessionStorage.setItem('hybrid', JSON.stringify(true))
      }

      // Adding Plugin Hybrid Range
      if (
        sessionStorage.getItem('car_policy_plug-in-hybrid_range') &&
        !sessionStorage.getItem('plug_in_hybrid_drive_range')
      ) {
        const carPolicyPluginHybridRange = JSON.parse(sessionStorage.getItem('car_policy_plug-in-hybrid_range') || '[]')
        sessionStorage.setItem('plug_in_hybrid', JSON.stringify(true))
        sessionStorage.setItem('plug_in_hybrid_drive_range', JSON.stringify(carPolicyPluginHybridRange))
      }
    }

    const initializeRequest = async () => {
      if (sessionStorage.getItem('car_policy_fuel_type') && !sessionStorage.getItem('fuel_type')) {
        const carPolicyFuelTypes = JSON.parse(sessionStorage.getItem('car_policy_fuel_type') || '[]')
        const filteredFuelTypes = carPolicyFuelTypes.filter((e) => e !== 'plug_in_hybrid' && e !== 'hybrid')

        //// Direct selection or direct at step 2
        if (!sessionStorage.getItem('plug_in_hybrid') && !sessionStorage.getItem('hybrid')) {
          settingCarPloiciesValues()
        }
        if (
          !sessionStorage.getItem('plug_in_hybrid') &&
          !sessionStorage.getItem('hybrid') &&
          filteredFuelTypes.length != 0
        ) {
          sessionStorage.setItem('fuel_type', JSON.stringify(filteredFuelTypes))
        }

        // Adding electric drive Range
        if (
          sessionStorage.getItem('car_policy_electric_range') &&
          !sessionStorage.getItem('electric_drive_range') &&
          filteredFuelTypes.includes('electric')
        ) {
          const carPolicyElectricRange = JSON.parse(sessionStorage.getItem('car_policy_electric_range') || '[]')
          sessionStorage.setItem('electric_drive_range', JSON.stringify(carPolicyElectricRange))
        }

        if (
          sessionStorage.getItem('car_policy_plug-in-hybrid_range') &&
          !sessionStorage.getItem('plug_in_hybrid_drive_range') &&
          sessionStorage.getItem('plug_in_hybrid')
        ) {
          const carPolicyPluginHybridRange = JSON.parse(
            sessionStorage.getItem('car_policy_plug-in-hybrid_range') || '[]'
          )
          sessionStorage.setItem('plug_in_hybrid_drive_range', JSON.stringify(carPolicyPluginHybridRange))
        }
      }
      if (sessionStorage.getItem('car_policy_body_type') && !sessionStorage.getItem('body_type')) {
        const allowedBodyType = JSON.parse(sessionStorage.getItem('car_policy_body_type') || '[]')
        sessionStorage.setItem('body_type', JSON.stringify(allowedBodyType))
      }

      if (sessionStorage.getItem('car_policy_doors') && !sessionStorage.getItem('doors')) {
        const minDoors = JSON.parse(sessionStorage.getItem('car_policy_doors') || '[]')
        sessionStorage.setItem('doors', JSON.stringify(minDoors['min']))
      }
      // If user has not selected the electric drive range, we pick electric range from car policy
      if (
        sessionStorage.getItem('fuel_type') &&
        sessionStorage.getItem('fuel_type').includes('electric') &&
        !sessionStorage.getItem('electric_drive_range') &&
        sessionStorage.getItem('car_policy_electric_range')
      ) {
        sessionStorage.setItem('electric_drive_range', sessionStorage.getItem('car_policy_electric_range'))
      }

      // If user has not selected the plugin hybrid range, we pick plugin hybrid range from car policy
      if (
        sessionStorage.getItem('plug_in_hybrid') &&
        !sessionStorage.getItem('plug_in_hybrid_drive_range') &&
        sessionStorage.getItem('car_policy_plug-in-hybrid_range')
      ) {
        sessionStorage.setItem('plug_in_hybrid_drive_range', sessionStorage.getItem('car_policy_plug-in-hybrid_range'))
      }

      wizardSteps.forEach((item) => {
        if (
          sessionStorage.getItem('electric_drive_range') &&
          JSON.parse(sessionStorage.getItem('electric_drive_range') || '') == 0
        ) {
          sessionStorage.removeItem('electric_drive_range')
        }
        if (
          sessionStorage.getItem('plug_in_hybrid_drive_range') &&
          JSON.parse(sessionStorage.getItem('plug_in_hybrid_drive_range') || '') == 0
        )
          sessionStorage.removeItem('plug_in_hybrid_drive_range')
        if (sessionStorage.getItem(item)) wizardRequestBody[item] = JSON.parse(sessionStorage.getItem(item) || '')
        else delete wizardRequestBody[item]

        if (
          wizardRequestBody['electric_drive_range'] &&
          (!wizardRequestBody['fuel_type'] || !wizardRequestBody['fuel_type'].includes('electric'))
        ) {
          delete wizardRequestBody['electric_drive_range']
        }
      })

      await store.dispatch(ActionTypes.POST_WIZARDSTEPS, wizardRequestBody)
    }
    onMounted(async () => {
      if (!displayUserSettingPopup.value) {
        await initializeRequest()
        if (backButtonPressed.value && window.history.state) {
          const left = window.history.state.scroll.left
          const top = window.history.state.scroll.top
          window.scrollTo({ left: left, top: top, behavior: 'smooth' })
        }
        await store.dispatch(userCarsActionTypes.GET_ALLSAVEDCARS, { status: 0 })
      }
    })
    const convertToCommas = (selectedOption) => {
      return selectedOption.replace('.', ',')
    }
    const hideFilters = () => {
      showFilterSidebar.value = false
    }
    const showFilters = () => {
      showFilterSidebar.value = true
    }
    const next = () => {
      const slides = selectionAmount.value + 1
      const carousel = document.getElementById('carousel')
      if (slides > position.value) {
        position.value = position.value + 1
        carousel?.scrollTo(269 * (position.value - 1) + 35 * (position.value - 1), 0)
      }
    }
    const previous = () => {
      const carousel = document.getElementById('carousel')
      if (position.value > 1) {
        position.value = position.value - 1
        if (position.value === 1) {
          carousel?.scrollTo(0, 0)
        }
        carousel?.scrollTo(269 * (position.value - 1) + 35 * (position.value - 1), 0)
      }
    }
    const moreOptions = () => {
      selectionAmount.value = selectionAmount.value + 20
      const carousel = document.getElementById('carousel')
      setTimeout(function () {
        carousel?.scrollTo(269 * (position.value - 1) + 35 * (position.value - 1), 0)
      }, 100)
      sessionStorage.setItem('selectionAmount', JSON.stringify(selectionAmount.value))
    }
    const selectAndConfigure = (vehicleId) => {
      const params = { vehicleId: vehicleId.slice(0, -8) }
      router.push({ name: 'Step3', params })
    }
    const applyFilters = async () => {
      await initializeRequest()
      hideFilters()
      showMoreVersion.value = false
    }
    const discardFilters = async () => {
      wizardSteps.forEach((item) => {
        if (wizardRequestBody[item]) sessionStorage.setItem(item, JSON.stringify(wizardRequestBody[item]))
        else sessionStorage.removeItem(item)
      })
      await initializeRequest()
      hideFilters()
      showMoreVersion.value = false
    }
    const moreVersions = (innerHits) => {
      showMoreVersion.value = true
      wizardInnerHits.value = innerHits['hits']
      brandAndModel.value['brand'] = innerHits['brand']
      brandAndModel.value['model'] = innerHits['model']
      window.scrollTo(0, 0)
    }

    const goToCompare = () => {
      const carOrderIds: number[] = allSavedCars.value.map((e) => {
        return e.id
      })
      router.push({
        name: 'CompareCars',
        query: { id: carOrderIds }
      })
    }
    return {
      store,
      showFilterSidebar,
      position,
      selectionAmount,
      formatCurrency,
      hideFilters,
      showFilters,
      next,
      previous,
      moreOptions,
      wizardResults,
      convertToCommas,
      loader,
      windowWidth,
      selectAndConfigure,
      applyFilters,
      discardFilters,
      wizardRequestBody,
      moreVersions,
      showMoreVersion,
      showResults,
      wizardInnerHits,
      brandAndModel,
      complyCarResponse,
      showComplyPopup,
      closeComplyPopup,
      options,
      toggleComplyPopup,
      userCarsLoader,
      goToCompare,
      allSavedCars,
      displayUserSettingPopup,
      goToAccount
    }
  }
})
