
import { defineComponent, reactive, watch } from 'vue'
import WizardCarBrandsModel from '@/components/WizardCarBrandsModels.vue'
import { carBrandsModelType } from '@/types'
import _ from 'lodash'
export default defineComponent({
  emits: ['selectedBrands'],
  props: {
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WizardCarBrandsModel
  },
  setup(props, { emit }) {
    const getBrandsOnly = (brandModelsArray) => {
      return brandModelsArray.map((item) => {
        return item.brand
      })
    }
    const selectedBrands = sessionStorage.getItem('brands')
      ? reactive({ value: getBrandsOnly(JSON.parse(sessionStorage.getItem('brands') || '[]')) })
      : reactive({ value: [] })
    const removeBrand = (brand) => {
      if (selectedBrands.value.indexOf(brand) !== -1) {
        selectedBrands.value.splice(selectedBrands.value.indexOf(brand), 1)
      }
    }
    const removeAllBrands = () => {
      if (sessionStorage.getItem('car_policy_brands_models')) {
        const brandsModelsArray = JSON.parse(sessionStorage.getItem('car_policy_brands_models') || '')
        const filteredArray = brandsModelsArray.map((item) => {
          return item.brand
        })
        selectedBrands.value = filteredArray
      } else {
        selectedBrands.value.splice(0, selectedBrands.value.length)
      }
    }
    const getFilteredBrands = (selectedBrands) => {
      let filteredBrandsModel: carBrandsModelType[] = []
      if (sessionStorage.getItem('car_policy_brands_models')) {
        const brandsModelsArray = JSON.parse(sessionStorage.getItem('car_policy_brands_models') || '')
        const filteredArray = brandsModelsArray.filter((item) => {
          return selectedBrands.includes(item.brand)
        })
        filteredBrandsModel = filteredArray
      } else {
        selectedBrands.forEach((item) => {
          filteredBrandsModel.push({ brand: item, models: [] })
        })
      }
      return filteredBrandsModel
    }
    watch(
      () => _.cloneDeep(selectedBrands.value),
      (currentValue) => {
        emit('selectedBrands', selectedBrands.value)
        if (!currentValue.length && !sessionStorage.getItem('car_policy_brands_models')) sessionStorage.removeItem('brands')
        else if (!currentValue.length && sessionStorage.getItem('car_policy_brands_models') ) {
          sessionStorage.setItem('brands', JSON.stringify(JSON.parse(sessionStorage.getItem('car_policy_brands_models') || '')))
        }
        else sessionStorage.setItem('brands', JSON.stringify(getFilteredBrands(currentValue)))
      }
    )
    const AddBrands = ($event) => {
      const value = $event.target.value
      if ($event.target.checked) {
        if (selectedBrands.value.indexOf(value) === -1) {
          selectedBrands.value.push(value)
        }
      } else {
        removeBrand(value)
      }
    }
    return { AddBrands, removeBrand, removeAllBrands, selectedBrands }
  }
})
