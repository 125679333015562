
import { defineComponent, ref, reactive, watch } from 'vue'
import VueSlider from 'vue-slider-component'
import sliderOptions from '@/mixins/sliderOptions'
export default defineComponent({
  components: {
    VueSlider
  },
  setup() {
    const sliderData = reactive([5, 4, 3, 2, 1])
    const sliderValue = sessionStorage.getItem('cargo_space')
      ? ref(JSON.parse(sessionStorage.getItem('cargo_space') || '') / 100)
      : ref(3)
    const { sliderDataOptions } = sliderOptions()
    watch(
      () => sliderValue.value,
      (currentValue) => {
        sessionStorage.setItem('cargo_space', JSON.stringify(currentValue * 100))
      }
    )
    return { sliderData, sliderValue, sliderDataOptions }
  }
})
