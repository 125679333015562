
import { defineComponent, ref, reactive, watch } from 'vue'
import VueSlider from 'vue-slider-component'
import sliderOptions from '@/mixins/sliderOptions'
export default defineComponent({
  components: {
    VueSlider
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const sliderValue = sessionStorage.getItem('towing_capacity')
      ? ref(JSON.parse(sessionStorage.getItem('towing_capacity') || ''))
      : ref([0])
    const sliderData = reactive([
      3500, 3400, 3300, 3200, 3100, 3000, 2900, 2800, 2700, 2600, 2500, 2400, 2300, 2200, 2100, 2000, 1900, 1800, 1700,
      1600, 1500, 1400, 1300, 1200, 1100, 1000, 900, 800, 700, 600, 500, 0
    ])
    watch(
      () => sliderValue.value,
      (currentValue) => {
        sessionStorage.setItem('towing_capacity', JSON.stringify(currentValue))
        if (currentValue == 0) {
          sessionStorage.removeItem('towing_capacity')
        }
      }
    )
    const { sliderDataOptions } = sliderOptions()
    return { sliderValue, sliderDataOptions, sliderData }
  }
})
