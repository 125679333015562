
import { defineComponent, ref, onMounted, onUnmounted, computed } from 'vue'
import checkUserSettings from '@/mixins/checkUserSettings'
import Input from '@/components/UI/Input.vue'
import Checkbox from '@/components/UI/Checkbox.vue'
import { MutationTypes } from '@/store/options/mutations'
import { ActionTypes } from '@/store/options/actions'
import { useStore } from '@/store'
export default defineComponent({
  components: {
    Input,
    Checkbox
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false
    },
    selectedBrands: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const el = ref<HTMLDivElement>(document.createElement('div'))
    const value = ref(store.state.options.searchString)
    const isDropdownOpen = ref(false)
    const filteredBrands = computed(() => store.getters.filteredBrands)
    const { displayUserSettingPopup } = checkUserSettings()
    const boldString = (str, find) => {
      return str.replace(new RegExp(find, 'gi'), (match) => {
        return '<b style="font-family: Lato-semibold">' + match + '</b>'
      })
    }
    const removeBrand = (brand) => {
      emit('removeBrand', brand)
    }
    const removeAllBrands = () => {
      emit('removeAllBrands')
    }
    const onCheck = ($event) => {
      emit('onCheck', $event)
    }
    const onInput = (value) => {
      store.commit(MutationTypes.SET_SEARCH_STRING, value)
    }
    const getBrandImages = (brand) => {
      const brandName = brand.toLowerCase().replace(/\s/g, '').replace(/ë/g, 'e').replace(/&/g, '').replace(/š/g, 's')
      return `${brandName}`
    }
    const closeDropdown = (e) => {
      const target = e.target
      if (el.value && el.value !== target && !el.value.contains(target)) {
        isDropdownOpen.value = false
      }
    }
    const initialize = async () => {
      if (!displayUserSettingPopup.value) {
        await store.dispatch(ActionTypes.GET_CAR_BRANDS_MODELS)
      }
    }
    const isAllBrandsSelected = () => {
      return sessionStorage.getItem('brands') == sessionStorage.getItem('car_policy_brands_models')
    }
    onMounted(async () => {
      document.addEventListener('click', closeDropdown)
      await initialize()
    })
    onUnmounted(() => {
      document.removeEventListener('click', closeDropdown)
    })
    return {
      value,
      onCheck,
      isDropdownOpen,
      removeBrand,
      removeAllBrands,
      closeDropdown,
      el,
      boldString,
      onInput,
      filteredBrands,
      getBrandImages,
      isAllBrandsSelected
    }
  }
})
