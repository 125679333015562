
import { defineComponent, computed, ref, ComputedRef } from 'vue'
import { useStore } from '@/store'
import formatCurrency from '@/filters/CurrencyFormat'
import Button from '@/components/UI/Button.vue'
import Popup from '@/components/UI/Popup.vue'
import getTranslatedBodyType from '@/filters/getTranslatedBodyType'
import { ActionTypes as userCarAction } from '@/store/userCars/actions'
import { savedCarsType } from '@/types'
export default defineComponent({
  emits: ['selectAndConfigure', 'moreVersions', 'toggleComplyPopup'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    wizardRequestBody: {
      type: Object,
      default: () => ({})
    },
    showMoreVersion: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Button,
    Popup
  },
  setup(props, { emit }) {
    const store = useStore()
    const imagesURl = process.env.VUE_APP_IMG_URL
    const showPopUp = ref(false)
    const loader = computed(() => store.state.userCars.loader)
    const showLessHighBudget = ref()
    const showLessElectricRange = ref()
    const showLessPlugInRange = ref()
    const comparisonStatus = ref()
    const electricComparisonStatus = ref()
    const complyCarResponse = computed(() => store.state.options.complyCarResponse)
    const vehicleIdWithoutYear = ref(
      props.item._source.vehicle_id.substring(0, props.item._source.vehicle_id.length - 8)
    )
    const allSavedCars: ComputedRef<savedCarsType[]> = computed(() => store.state.userCars.savedCars)
    const savedCarsIds = computed(() => store.state.userCars.savedCarsIds)
    const isFavorite = computed(
      () => savedCarsIds.value.findIndex((e) => e === vehicleIdWithoutYear.value.toString()) !== -1
    )
    const formatMissingEquipments = (selectedOption) => {
      return selectedOption.replaceAll(' ', '_').toLowerCase()
    }
    const convertToCommas = (selectedOption) => {
      return selectedOption.replace('.', ',')
    }
    const getMatchPercentage = (score) => {
      const entireMaxScore = (score / 1) * 100
      return Math.round(entireMaxScore)
    }
    const getMatchPercentageColors = (score) => {
      let color = 'grey'
      if (getMatchPercentage(score) == 100) color = 'green'
      else if (getMatchPercentage(score) < 100 && getMatchPercentage(score) >= 80) color = 'gradient'
      else if (getMatchPercentage(score) <= 79) color = 'grey'
      return color
    }
    const openPopup = () => {
      showPopUp.value = true
    }
    const toggleFavorite = async () => {
      const carBody = {
        vehicleId: props.item._source.vehicle_id,
        monthlyCost: props.item._source.budget_monthly,
        carPrice: props.item._source.price,
        co2Emission: props.item._source.co2_emission,
        status: false,
        colors: [],
        options: [], // Needs to be changed in further steps
        otherAccessories: [],
        chargingFacilities: []
      }
      if (!isFavorite.value) {
        await store.dispatch(userCarAction.SAVE_CAR_ORDER, carBody)
        if (complyCarResponse.value && complyCarResponse.value.length) {
          emit('toggleComplyPopup')
        }
      } else {
        const savedCarIndex = allSavedCars.value.findIndex(
          (e) => +e.vehicleDetails.unique_identity_101 === +vehicleIdWithoutYear.value
        )
        if (savedCarIndex !== -1) {
          const savedCarId = allSavedCars.value[savedCarIndex].id
          await store.dispatch(userCarAction.DELETE_SAVED_CAR, [savedCarId])
        }
      }
    }
    const getComparisonBudget = (budget) => {
      if (props.wizardRequestBody.budget) {
        const selectedBudget = props.wizardRequestBody.budget
        if (budget < selectedBudget[0]) {
          comparisonStatus.value = 'lower'
          showLessHighBudget.value = (budget / selectedBudget[0]) * 100 - 100
        } else if (budget > selectedBudget[1]) {
          comparisonStatus.value = 'higher'
          showLessHighBudget.value = (budget / selectedBudget[1]) * 100 - 100
        } else if (selectedBudget[0] < budget < selectedBudget[1]) {
          return false
        }
      }
      return comparisonStatus.value
    }
    const getComparisonEnginePower = (power) => {
      power = parseFloat(power)
      let comparisonStatus = ''
      if (props.wizardRequestBody.engine_power) {
        const selectedEnginePower = parseInt(props.wizardRequestBody.engine_power)
        if (power > selectedEnginePower) {
          comparisonStatus = 'lower'
        }
      }
      return comparisonStatus
    }
    const getComparisonElectricRange = (electricRange) => {
      const userInput = parseInt(props.wizardRequestBody.electric_drive_range)
      electricRange = parseInt(electricRange)
      if (userInput !== 1) {
        if (electricRange < userInput) {
          electricComparisonStatus.value = 'less'
          showLessElectricRange.value = 100 - (electricRange / userInput) * 100
        }
      }
      return electricComparisonStatus.value
    }
    const getComparisonPlugInRange = (plugInRange) => {
      const userInput = parseInt(props.wizardRequestBody.plug_in_hybrid_drive_range)
      plugInRange = parseInt(plugInRange)
      if (userInput !== 1) {
        if (plugInRange < userInput) {
          electricComparisonStatus.value = 'less'
          showLessPlugInRange.value = 100 - (plugInRange / userInput) * 100
        }
      }
      return electricComparisonStatus.value
    }
    const getTranslatedTransmission = (selectedTransmission) => {
      let translatedTransmission = '_wizard.step4.manual'
      if (selectedTransmission == 'Automatic') {
        translatedTransmission = '_wizard.step4.automatic'
      }
      return translatedTransmission
    }
    const capitalize = (bodyType) => {
      let value
      if (bodyType !== 'SUV' && bodyType !== 'MPV') {
        value = bodyType.charAt(0).toUpperCase() + bodyType.slice(1).toLowerCase()
      } else {
        value = bodyType
      }
      return value
    }
    /* const checkIfBodyTypeExist = (bodyType) => {
      if (props.wizardRequestBody.body_type) {
        const lowercased = props.wizardRequestBody.body_type.map((name) => name.toLowerCase())
        if (!lowercased.includes(bodyType.toLowerCase())) {
          return capitalize(bodyType)
        } else {
          return false
        }
      }
    } */
    return {
      convertToCommas,
      formatCurrency,
      getMatchPercentage,
      getMatchPercentageColors,
      openPopup,
      showPopUp,
      isFavorite,
      toggleFavorite,
      getComparisonBudget,
      getComparisonEnginePower,
      formatMissingEquipments,
      showLessHighBudget,
      comparisonStatus,
      getComparisonElectricRange,
      showLessElectricRange,
      electricComparisonStatus,
      getComparisonPlugInRange,
      showLessPlugInRange,
      capitalize,
      getTranslatedTransmission,
      getTranslatedBodyType,
      imagesURl,
      loader,
      vehicleIdWithoutYear
    }
  }
})
