
import Button from '@/components/UI/Button.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    Button
  },
  props: {
    textToShow: {
      type: String,
    }
  }
})
