
import { defineComponent, ref, reactive, watch, onMounted } from 'vue'
import VueSlider from 'vue-slider-component'
import sliderOptions from '@/mixins/sliderOptions'
export default defineComponent({
  components: {
    VueSlider
  },
  props: {
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const sliderValue = sessionStorage.getItem('seats')
      ? ref(JSON.parse(sessionStorage.getItem('seats') || ''))
      : ref(4)
    onMounted(() => {
      if (!props.isFilter) sessionStorage.setItem('seats', JSON.stringify(sliderValue.value))
    })
    const sliderData = reactive([9, 8, 7, 6, 5, 4, 3, 2])
    watch(
      () => sliderValue.value,
      (currentValue) => {
        sessionStorage.setItem('seats', JSON.stringify(currentValue))
      }
    )
    const { sliderDataOptions } = sliderOptions()
    return { sliderValue, sliderDataOptions, sliderData }
  }
})
