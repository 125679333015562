
import { defineComponent, reactive } from 'vue'
import Checkbox from '@/components/UI/Checkbox.vue'
export default defineComponent({
  components: {
    Checkbox
  },
  props: {
    checkboxesValues: {
      type: Object,
      default: () => ({})
    },
    isFilter: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const gearBoxArray: Array<string> = sessionStorage.getItem('transmission')
      ? reactive(JSON.parse(sessionStorage.getItem('transmission') || '[]'))
      : reactive([])
    const onCheck = ($event, title) => {
      if (gearBoxArray.indexOf(title) === -1) {
        gearBoxArray.push(title)
      }
      if (!$event.target.checked) {
        gearBoxArray.splice(gearBoxArray.indexOf(title), 1)
      }
      if (!gearBoxArray.length) sessionStorage.removeItem('transmission')
      else sessionStorage.setItem('transmission', JSON.stringify(gearBoxArray))
    }
    return { onCheck }
  }
})
